import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import CutContainer from "../components/cutContainer"
import { grayGradient9 } from "../services/constants"
import Testimonial from "../components/testimonial"
import styles from "./testimonials.module.scss"
import EmailInput from "../components/emailInput"
import Header from "../components/header"
import Footer from "../components/footer"
import globalStyles from "../services/globalStyles.module.scss"

class TestimonialsPage extends React.Component {
  render() {
    const { allWordpressTestimonials, texts: { _ } } = this.props.data;
    const languageCode = this.props.pageContext && this.props.pageContext.lang ? this.props.pageContext.lang : "en";

    return (
      <Layout header={<Header num={1} translations={_} headerFullYOffset={500} fixed mobileHeaderFullYOffset={40} languageCode={languageCode}/>} translations={_} languageCode={languageCode}>
        <CutContainer
          style={{
            background: grayGradient9,
            position: "relative",
          }}
          sizeOfCut={1}
          styleClass={styles.cutContainer}
        >
          <h1 className={styles.header}>
            {_.testimonials.header}
        </h1>
        </CutContainer>
        <div className={styles.testimonialWrap}>
          <div className={styles.testimonialContent}>
            {
              allWordpressTestimonials.edges.map((val, index) => {
                if (!val.node.title) return null
                return <div key={index} className={styles.testimonialContainer}>
                  <Testimonial
                    location={val.node.location}
                    quote={val.node.quote}
                    avatar={val.node.image}
                    title={val.node.title.rendered}
                    subtitle={val.node.occupation}
                    video={val.node.video} />
                </div>
              })
            }
          </div>
        </div>
        <div>
          <EmailInput
            title={_.time_to_get_started}
            placeholder={_.enter_your_email}
            buttonText={_.start_free_trial}
            translations={_.contact_message}
          />
        </div>
        <Header num={2} translations={_} className={globalStyles.hideOnMobile} headerFullYOffset={500} languageCode={languageCode}/>
        <Footer translations={_} languageCode={languageCode}/>
      </Layout>
    )
  }
}

export default TestimonialsPage
export const query = graphql`
  query Testimonials($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: {eq: $langFile}) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...EmailTranslations
        enter_your_name
        enter_your_email
        start_free_trial
        time_to_get_started
        testimonials{
          header
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
      }
    },
    allWordpressTestimonials {
      edges {
        node {
          title { rendered }
          location: wpcf_location
          quote: wpcf_quote
          occupation: wpcf_position
          video: wpcf_url_video
          image: wpcf_profile_image
        }
      }
    }
  }
`
